@import url('https://fonts.googleapis.com/css?family=Lora:400,700,400italic|Oswald:400,700');
@import 'src/styles/variables.scss';

.config-wrapper {
  margin-left: 18px !important;
  margin-right: 18px !important;
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.config-logo-header-wrapper {
  background-color: $primary-color;
  height: 54px !important;
  margin-bottom: 12px !important;
  display: flex !important;
  align-items: center !important;
}

.config-logo-header {
  height: 40px !important;
}

.config-title {
  font-size: 20px;
  font-weight: 500 !important;
  font-family: 'Oswald' !important;
  color: $secondary-color;
}

.config-title-main {
  margin-bottom: 12px !important;
}

.config-body-text {
  color: $secondary-color;
  font-size: 12px !important;
  font-family: 'Poppins' !important;
  font-weight: 300 !important;
  line-height: 16px !important;
}

.config-button {
  font-weight: 600 !important;
  font-size: 12px !important;
  font-family: 'Poppins' !important;
  letter-spacing: 0.3px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 24px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  width: 84px !important;
  margin-left: 12px !important;
}

.config-button-primary {
  border: 2px $primary-color !important;
  background-color: $primary-color !important;
  color: $white !important;
  margin-right: 0px !important;
}

.config-button-secondary {
  border: 1px solid $dark-gray !important;
  background-color: $white !important;
  letter-spacing: 0.4px;
  color: $ace-editor-bg !important;
}

.config-button-primary:hover {
  color: $yellow !important;
}

.config-button-secondary:hover {
  background-color: $light-gray !important;
}
