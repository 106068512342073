@import 'variables';

.grid-explorer .MuiDataGrid-footerContainer {
  margin-right: 50px !important;
}

/* SQLMorph */

.ace-solarized-dark .ace_gutter {
  background: $light-gray !important;
  color: $secondary-color !important;
}

.ace-solarized-dark {
  background: $ace-editor-bg;
}

.ace_gutter-active-line {
  background: $ace-editor-bg !important;
  color: $white;
}

.ace_editor {
  color: $light-gray;
}

.ace_print-margin {
  background: $primary-color;
}

.ace_keyword {
  color: invert($primary-color) !important;
}

.ace-solarized-dark .ace_comment {
  color: $white;
}

.highlight-line {
  position: absolute;
  background: darkred;
}

.highlight-word {
  position: absolute;
  background: #999900;
  border-radius: 0;
}

#toc.toc2 {
  top: 50px !important;
  height: calc(100vh - 80px) !important;
  background: #fff !important;
}

#content {
  max-width: none !important;
  margin-left: 15px !important;
}

@media print {
  a[href]:after {
    content: '' !important;
  }
}
