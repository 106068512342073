// Brand colors
$primary-color: #264369;
$secondary-color: #333332;
$white: #ffffff;
$light-gray: #e9edee;
$dark-gray: #a8a8a8;
$yellow: #ffd65c;
$red: #ed304f;
$blue: #28a745;
$lime-green: #32cd32;
$ace-editor-bg: #40403f;
