@import url('https://fonts.googleapis.com/css?family=Lora:400,700,400italic|Oswald:400,700');
@import '../styles/extensions-common';

/* variables */
$font-family: 'Helvetica Neue' !important;
$small-font-size: 10px;
$time-input-font-size: 15px !important;
/* End variables */

/* Main component */
.refresh-tooltip {
  font-family: $font-family;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.last-refresh-text {
  font-size: 15px;
  font-weight: 700 !important;
  font-family: $font-family;
  color: $secondary-color;
}

.last-refresh-label {
  font-family: $font-family;
  color: $secondary-color;
  font-size: $small-font-size;
}

.last-refresh-text-short,
.countdown-text-short {
  display: none;
}

.countdown-text-number {
  font-weight: bold;
}

.countdown-text,
.countdown-text-number {
  font-family: $font-family;
  color: $secondary-color;
  font-size: $small-font-size;
}

/* handle width changes */
@media screen and (max-width: 200px) {
  .countdown-text {
    display: none;
  }

  .countdown-text-short {
    color: $secondary-color;
    font-size: 10px;
    display: inline;
  }

  .last-refresh-text {
    display: none;
  }

  .last-refresh-text-short {
    font-weight: 700 !important;
    font-size: 13px;
    font-family: $font-family;
    padding-left: 1px;
    padding-right: 1px;
    color: $secondary-color;
    display: block;
  }
}

@media screen and (max-width: 100px) {
  .countdown-text {
    display: none;
  }

  .countdown-text-number {
    display: none;
  }

  .countdown-text-short {
    font-size: 7px;
  }
}

/* handle height changes */
@media screen and (max-height: 70px) {
  .hidden-custom-progress {
    display: none !important;
  }
}

@media screen and (max-height: 45px) {
  .countdown-text-short,
  .countdown-text,
  .countdown-text-number {
    display: none !important;
  }
}

@media screen and (max-height: 30px) {
  .last-refresh-label {
    display: none !important;
  }
}
/* End main component */

/* Time picker */
.react-time-picker__wrapper,
.react-time-picker--disabled {
  border: none !important;
  border-radius: 0.375rem !important;
  background-color: $light-gray !important;
  display: flex !important;
  height: 25px !important;
  width: 121px !important;
  margin-right: 12px !important;
}

.react-time-picker__inputGroup__input {
  font-family: 'Poppins' !important;
  color: $secondary-color !important;
  font-size: $time-input-font-size;
  padding: 0px !important;
}

.react-time-picker__inputGroup {
  display: flex !important;
  align-items: center !important;
  padding-left: 6px !important;
  justify-content: space-evenly !important;
}

.react-time-picker__inputGroup__leadingZero {
  display: flex !important;
  align-items: center !important;
  color: $secondary-color !important;
  font-size: $time-input-font-size;
  font-family: 'Poppins' !important;
  line-height: 100% !important;
}

.react-time-picker__inputGroup__hour,
.react-time-picker__inputGroup__minute {
  font-size: $time-input-font-size;
  display: flex !important;
  align-items: center !important;
  width: 20px !important;
  text-align: center !important;
}

.react-time-picker__inputGroup__leadingZero
  + .react-time-picker__inputGroup__minute {
  width: 10px !important;
  margin-left: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.react-time-picker__inputGroup__divider
  + .react-time-picker__inputGroup__leadingZero {
  margin: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.react-time-picker__inputGroup__leadingZero
  + .react-time-picker__inputGroup__hour {
  width: 10px !important;
  margin-left: 0px !important;
  display: flex !important;
  align-items: center !important;
}

.react-time-picker__inputGroup__divider {
  display: flex !important;
  align-items: center !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
  font-size: $time-input-font-size;
  height: 100% !important;
  padding-bottom: 5px !important;
}

.react-time-picker__inputGroup__amPm {
  border-radius: 0.5rem !important;
  background-color: $light-gray !important;
  font-family: 'Poppins' !important;
  align-self: flex-end !important;
}

.react-time-picker__inputGroup__amPm > option {
  font-size: $time-input-font-size;
  font-family: 'Poppins' !important;
  padding-left: 1px;
  padding-right: 1px;
  color: $secondary-color;
  background-color: $light-gray !important;
}
/* End time picker */

/* Config */
.config-section-container {
  margin-bottom: 18px !important;
}

.time-input {
  height: 24px;
  font-size: $time-input-font-size;
  font-family: 'Poppins' !important;
  color: $secondary-color;
  border-radius: 0.375rem !important;
  border: none;
  background-color: $light-gray !important;
  padding-left: 6px !important;
  width: 121px;
}

.colon-span {
  margin-right: 6px;
  margin-left: 6px;
  align-self: center !important;
}
/* End config */
