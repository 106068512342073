@import 'src/styles/variables.scss';
.header-paper {
  box-shadow:
    0px 2px 0px -1px rgb(0 0 0 / 20%),
    0px 1px 0px 0px rgb(0 0 0 / 14%),
    0px 1px 0px 0px rgb(0 0 0 / 12%) !important;
  border-radius: 0px !important;
  .header-tabs {
    button[role='tab'],
    a[role='tab'] {
      text-transform: capitalize;
      cursor: pointer !important;
      color: #000000 !important;
    }
    a[role='tab'].Mui-selected {
      color: #386aaa !important;
    }
  }
}
