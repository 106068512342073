@import 'variables';

.space-top-lg {
  margin-top: 20px !important;
}

.space-top-padding-lg {
  padding-top: 20px !important;
}

.space-top-md {
  margin-top: 10px !important;
}

.space-bottom-lg {
  margin-bottom: 20px !important;
}

.space-around {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mr-btm10 {
  padding-bottom: 10 !important;
}

.page-content {
  margin-left: 10%;
  margin-right: 10%;
  width: inherit !important;
  height: 100%;
}

.center {
  display: flex;
  justify-content: center;
}

.capitalize {
  text-transform: capitalize !important;
}

.modal-content {
  min-width: 700px;
  width: 100%;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.code {
  padding: 10px 100px 10px 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  overflow: auto;
  font-size: 13px;
  word-break: break-all;
}

.shine_btn {
  animation: shine 1.5s;
  animation-iteration-count: infinite;
}

@keyframes shine {
  0% {
    box-shadow: 0px 2px 14px 1px #264369;
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0px 2px 14px 1px #264369;
  }
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

/* SQLMorph */

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mrTp65 {
  margin-top: 65px;
}

.mr-rt4 {
  margin-right: 4px !important;
}

.mr-rt10 {
  margin-right: 10px !important;
}

.mr-rt15 {
  margin-right: 15px !important;
}

.mr-rt20 {
  margin-right: 20px !important;
}

.mr-rt25 {
  margin-right: 25px !important;
}

.mr-lt5 {
  margin-left: 5px !important;
}

.mr-lt10 {
  margin-left: 10px !important;
}

.mr-lt15 {
  margin-left: 15px !important;
}

.mr-tp10 {
  margin-top: 15px !important;
}

.mr-tp15 {
  margin-top: 15px !important;
}

.mr-btm10 {
  margin-bottom: 10px !important;
}

.mr-btm8 {
  margin-bottom: 8px !important;
}

.mr-btm15 {
  margin-bottom: 15px !important;
}

.badge-count {
  justify-content: center;
  align-items: center;
  span {
    right: -10px;
    top: 15px;
  }
}

.badge-position {
  span {
    top: 0;
    right: 0;
  }
}

.badge-yellow {
  color: $yellow;
  span {
    top: -2px;
    right: -5px;
    background: $yellow;
    color: #ffffff;
  }
}

.badge-red {
  color: $red;
  span {
    top: -2px;
    right: -5px;
    background: $red;
    color: #ffffff;
  }
}

.badge-compiles-red {
  color: $red;
  span {
    background: transparent;
  }
}

.badge-compiles-blue {
  color: $blue;
  span {
    background: transparent;
  }
}

button.primary:disabled {
  color: $dark-gray;
}

.space-around {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.notif-close-btn {
  padding: 5px !important;
  color: #ffffff !important;
}

.card-header {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.text-indent {
  p {
    text-indent: 2em;
  }
  margin-left: 2em;
}

.margin-auto-0 {
  margin: auto 0 !important;
}

.relative {
  position: relative !important;
}

.error-bg {
  background: $red;
  height: 25px;
  color: #ffffff;
}

.warning-bg {
  background: $yellow;
  height: 25px;
  color: #ffffff;
}

.green-color {
  color: $lime-green;
}

.overflow-x-auto {
  pre {
    overflow-x: auto !important;
  }
}

.pd5-arround {
  padding-left: 5px;
  padding-right: 5px;
}
