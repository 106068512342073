@import '../common/variables.scss';

.SnackbarContainer-bottom {
  bottom: 44px !important;
  z-index: 1;
}

.SnackbarContainer-top.SnackbarContainer-right {
  top: 48px !important;
  z-index: 1050;
}

.snackbar-root > div {
  background-color: $primary-color;
  color: $comment-color;
}
