@import 'variables';

html,
body {
  overflow: hidden;
}

.left-nav {
  position: relative;
  .expand {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 15px;
    left: 0;
    background: #e9edee;
    z-index: 100;
    margin-left: -3px;
    transition: left 0.225s ease 0s;
  }
  .collapse {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 12px;
    right: 0;
    background: #e9edee;
    z-index: 100;
    transition: left 0.225s ease 0s;
  }
  .icon-main-section {
    cursor: pointer;
    .icon-section {
      position: absolute;
      top: 0;
      bottom: 0;
      .btn-arrow {
        position: absolute;
        top: 45%;
      }
    }
  }
}
